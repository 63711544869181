import Vue from 'vue'



export default {
    namespaced: true,
    state: {
        organizations: [],
        organization: {},
        image64: null,
    },
    getters: {
        getOrganizations(state) {
            return state.organizations
        },
        getOrganization(state) {
            return state.organization
        },

    },


    mutations: {
        setOrganizations(state, organizations) {
            state.organizations = organizations
        },
        setOrganization(state, organization) {
            state.organization = organization
        },
    },


    actions: {
        async loadOrganizations(ctx) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const user = await ctx.rootGetters['auth/getUser']

            const url =  '/api/v1/organisations'
            return await Vue.prototype.axios({
                method: "get",
                url: url,
                params: { customer_id: user.customer_id},
                headers: headers
            }).then(resp => {
                const organizations = resp.data
                ctx.commit('setOrganizations', organizations)
                return resp.status
            }).catch(error => {
                // // console.log(error)
                return error.response.status
            })
        },

        async loadParticipantList(ctx,data) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const url =  '/api/v1/reports/id-card-report';
            console.log(data)
            return await Vue.prototype.axios({
                method: "post",
                url: url,
                data: { organisation_id: data.id,
                            person_ids: data.list,
                            valid_until: data.dt},
                headers: headers,
                responseType: 'blob'
            }).then(resp => {
                const url = URL.createObjectURL(resp.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download',"Teilnehmerliste"+".zip");
                document.body.append(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);

            }).catch(error => {
                // Handle error
                console.error("Error downloading PDF:", error);
                return error.response.status;
            });
        },
        

        async loadOrganization(ctx, id) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            const url =  '/api/v1/organisations/' + id
            return await Vue.prototype.axios({
                method: "get",
                url: url,
                headers: headers
            }).then(resp => {
                const organization = resp.data
                ctx.commit('setOrganization', organization)
                return resp.status
            }).catch(error => {
                // // console.log(error)
                return error.response.status
            })
        },



        async createOrganization(ctx, organization) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const user = ctx.rootGetters['auth/getUser']

            const url =  '/api/v1/organisations'
            return await Vue.prototype.axios({
                method: "post",
                url: url,
                data: {
                    // created_by: user.id,
                    customer_id: user.customer_id,
                    id_card_layout_id: organization.id_card_layout_id,
                    name: organization.name,
                    issuing_authority_name: organization.issuing_authority_name,
                },
                headers: headers 
            }).then(resp => {
                const organization = resp.data
                ctx.dispatch('loadOrganizations')
                                return resp.status
            }).catch(error => {
                // // console.log(error)
                return error.response.status
            })
        },


        async updateOrganization(ctx, organization) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })
            const user = ctx.rootGetters['auth/getUser']

            const url =  '/api/v1/organisations/' + organization.id 
            return await Vue.prototype.axios({
                method: "put",
                url: url,
                data: {
                    // created_by: user.id,
                    customer_id: user.customer_id,
                    // last_modified_by: user.id,
                    // created_at: "2022-03-07T20:47:29.246Z",
                    // updated_at: "2022-03-07T20:47:29.246Z",
                    logo_image_id: organization.logo_image_id,
                    name: organization.name,
                    issuing_authority_name: organization.issuing_authority_name,
                },
                headers: headers
            }).then(resp => {
                const organization = resp.data
                ctx.commit('setOrganization', organization)
                ctx.dispatch('loadOrganizations')
                return resp.status
            }).catch(error => {
                // // console.log(error)
                return error.response.status
            })
        },



        async deleteOrganization(ctx, id) {
            const headers = await ctx.dispatch('auth/getHeaders', null, { root: true })

            const url =  '/api/v1/organisations/' + id
            return await Vue.prototype.axios({
                method: "delete",
                url: url,
                headers: headers
            }).then(resp => {
                ctx.dispatch('loadOrganizations')
                return resp.status
            }).catch(error => {
                // // console.log(error)
                return error.response.status
            })

        },



    }
}
