import Vue from "vue";

// Takes simple array and males 2d Array
function fields2dEncoder(array) {
  let newArray = []

  array.forEach((e, idx) => {
    if ((idx % 2) == 0) {
      newArray.push([])
    }
    newArray[newArray.length - 1].push(e)
  });

  return newArray
}


// Takes simple array and males 2d Array
function fields2dDecoder(array) {
  let newArray = []

  array.forEach((row, idx) => {
    row.forEach(e => {
      newArray.push(e)
    });
  });

  return newArray
}


function is2dArray(array) {
  if (array.length > 0) {
    if (Array.isArray(array[0])) {
      return true
    }
  }
  return false
}


export default {
  namespaced: true,
  state: {
    layout: {},
    layouts: {},
    templates: {},
    customerTemplates: {},
  },

  getters: {
    getLayout(state) {
      return state.layout;
    },
    getLayouts(state) {
      return state.layouts;
    },
    getTemplates(state) {
      return state.templates;
    },
    getCustomerTemplates(state) {
      return state.customerTemplates;
    },
  },

  mutations: {
    setLayout(state, layout) {
      state.layout = layout;
    },
    setLayouts(state, layouts) {
      state.layouts = layouts;
    },
    setTemplates(state, templates) {
      state.templates = templates;
    },
    setCustomerTemplates(state, customerTemplates) {
      state.customerTemplates = customerTemplates;
    },
    setLayoutImageTopRightLogo(state, id) {
      state.layout.header_picture_id = id;
    },
  },

  actions: {
    async loadLayouts(ctx) {
      const headers = await ctx.dispatch("auth/getHeaders", null, { root: true });
      
      const url =  "/api/v1/customizings/layouts";
      return await Vue.prototype.axios({
          method: "get",
          url: url,
          params:{
            sort_by: "name"
          },
          headers: headers,
        }).then((resp) => {
          ctx.commit("setLayouts", resp.data);
          return resp.status
        }).catch((error) => {
          // // console.log(error);
          return error.response.status
        });
    },

    async loadTemplates(ctx) {
      const headers = await ctx.dispatch("auth/getHeaders", null, { root: true });

      const url = "/api/v1/customizings/layouts";
      return await Vue.prototype.axios({
          method: "get",
          url: url,
          params: {
            is_template: true,
            sort_by: "name",
          },
          headers: headers,
        })
        .then((resp) => {
          ctx.commit("setTemplates", resp.data);
          return resp.status
        })
        .catch((error) => {
          // // console.log(error);
          return error.response.status
        });
    },

    async loadCustomerTemplates(ctx) {
      const headers = await ctx.dispatch("auth/getHeaders", null, { root: true });
      const user = await ctx.rootGetters["auth/getUser"]

      const url = "/api/v1/customizings/layouts";
      return await Vue.prototype.axios({
          method: "get",
          url: url,
          params: {
            is_template: true,
            template_of_customer_id: user.customer_id
          },
          headers: headers,
        }).then((resp) => {
          ctx.commit("setCustomerTemplates", resp.data);
          return resp.status
        }).catch((error) => {
          // // console.log(error);
          return error.response.status
        });

    },

    async loadLayout(ctx, layoutId) {
      const headers = await ctx.dispatch("auth/getHeaders", null, { root: true });
      const user = ctx.rootGetters["auth/getUser"];

      const url =  "/api/v1/customizings/layouts/" + layoutId;
      return await Vue.prototype.axios({
          method: "get",
          url: url,
          headers: headers,
        }).then((resp) => {
          const layout = resp.data;
          layout.stylesheet.fields_front = fields2dDecoder(layout.stylesheet.fields_front)
          layout.stylesheet.fields_back = fields2dDecoder(layout.stylesheet.fields_back)
          ctx.commit("setLayout", layout);
          return resp.status
        }).catch((error) => {
          // // console.log(error);
          return error.response.status
        });
    },

    async createLayout(ctx, layout) {
      const headers = await ctx.dispatch("auth/getHeaders", null, { root: true });
      const user = ctx.rootGetters["auth/getUser"];

      if (!is2dArray(layout.stylesheet.fields_front)) {
        layout.stylesheet.fields_front = fields2dEncoder(layout.stylesheet.fields_front)
      }
      if (!is2dArray(layout.stylesheet.fields_back)) {
      layout.stylesheet.fields_back = fields2dEncoder(layout.stylesheet.fields_back)
      }

      const url =  "/api/v1/customizings/layouts";
      return await Vue.prototype.axios({
          method: "post",
          url: url,
          data: layout,
          headers: headers,
        }).then((resp) => {
          const layout = resp.data;
          ctx.commit("setLayout", layout);
          ctx.dispatch("loadCustomerTemplates");
          return resp.status;
        }).catch((error) => {
          // // console.log(error);
          return error.response.status
        });
    },

    async updateLayout(ctx, layout) {
      const headers = await ctx.dispatch("auth/getHeaders", null, { root: true });
      const user = ctx.rootGetters["auth/getUser"];

      layout.stylesheet.fields_front = fields2dEncoder(layout.stylesheet.fields_front)
      layout.stylesheet.fields_back = fields2dEncoder(layout.stylesheet.fields_back)

      const url =  "/api/v1/customizings/layouts/" + layout.id;
      return await Vue.prototype.axios({
          method: "put",
          url: url,
          data: layout,
          headers: headers,
        }).then((resp) => {
          ctx.dispatch("loadCustomerTemplates");
          return resp.status
        }).catch((error) => {
          // // console.log(error);
          return error.response.status
        });
    },

    async deleteLayout(ctx, id) {
      const headers = await ctx.dispatch("auth/getHeaders", null, { root: true });
      const user = ctx.rootGetters["auth/getUser"];

      const url =  "/api/v1/customizings/layouts/" + id;
      return await Vue.prototype.axios({
          method: "delete",
          url: url,
          headers: headers,
        }).then((resp) => {
          ctx.dispatch("loadCustomerTemplates");
          return resp.status
        }).catch((error) => {
          // // console.log(error);
          return error.response.status
        });
    },

    setLayoutImageTopRightLogo(ctx, imageId) {
      ctx.commit("setLayoutImageTopRightLogo", imageId);
    },





  },
};
