import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import 'material-icons/iconfont/material-icons.css';



Vue.use(Vuesax,{
  theme:{
    colors:{
      default:'#005B9B',
      primary:'#005B9B',
      success:'rgb(23, 201, 100)',
      danger:'#B40000',
      warning:'rgb(238,174,91)', //'rgb(255, 130, 0)'
      dark:'rgb(25, 135, 84)'
    }
  }
})


Vue.config.productionTip = false
Vue.prototype.$debug = true

import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/BOS-ID.css'

import InputFacade from 'vue-input-facade'
Vue.use(InputFacade)

import { i18n } from './plugins/i18n'

// VUE AXIOS
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// Vue.axios.defaults.baseURL = process.env.VUE_APP_BOSID_API_HOST
// Vue.axios.defaults.crossDomain = true;

Vue.axios.defaults.withCredentials = true
Vue.axios.defaults.mode = "cors" 
Vue.axios.defaults.credentials = "include"
Vue.axios.defaults.timeout = 120000


//  VUE COOKIES
import VueCookies from 'vue-cookies'

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expire: '7d' })



Vue.axios.interceptors.response.use((response) => {
  return response;
}, async function (error) {

  //  HANDLE REAUTHORIZATION
  if (error.response.status === 401) {
    const refreshTokenUrl = process.env.VUE_APP_BOSID_API_HOST + '/api/v1/accounts/token/refresh'
    const origConfig = error.response.config

    if (origConfig.url !== refreshTokenUrl && !origConfig._retry) {
      origConfig._retry = true
      await store.dispatch('auth/refreshToken')

      // Replace new Token in Header
      origConfig.headers = await store.dispatch('auth/getHeaders', null, { root: true })
      return axios.request(origConfig)
    } else {
      // await store.dispatch('auth/makeLogout')
      router.push({ name: 'Login'})
    }
    return Promise.reject(error)
  }


  // //  Handle 403 Errors
  // if (error.response.status === 403) {
  //   Vue.prototype.$vs.notify({
  //     title: "Fehler",
  //     text: "Zugriff verweigert, die Daten wurden nicht gespeichert.",
  //     color: 'danger',
  //   });
  //   return Promise.reject(error)
  // }

  // //  Handle 4XX Errors
  // if ((/^4(.*){2}$/.test(error.response.status)) && !(error.response.config.url.includes("login"))) {
  //   Vue.prototype.$vs.notify({
  //     title: "Fehler",
  //     text: "Es ist ein Fehler aufgetreten, die Daten wurden nicht gespeichert.",
  //     color: 'danger',
  //   });
  // }

  // //  Handle 5XX Errors
  // if (/^5(.*){2}$/.test(error.response.status)) {
  //   Vue.prototype.$vs.notify({
  //     title: "Fehler",
  //     text: "Es ist ein Fehler aufgetreten, bitte wenden Sie sich an unseren Support, wenn der Fehler weiterhin auftritt.",
  //     color: 'danger',
  //   });
  // }
  


  return Promise.reject(error)
});



// DEBUG MODE
Vue.mixin({
  data: function () {
    return {
      get isDebugMode() {
        return false;
      }
    }
  }
})


Vue.use(require('vue-moment'));


new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
