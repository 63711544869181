import auth from './auth'
import organization from './organization'
import user from './user'
import customizing from './customizing'
import changeRequest from './changeRequest'
import idCard from './idCard'
import field from './field'
import layout from './layout'
import core from './core'
import person from './person'
import customer from './customer'




export default {
    auth,
    organization,
    user,
    customizing,
    changeRequest,
    idCard,
    field,
    layout,
    core,
    person,
    customer,
}