import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

import modules from './modules'
export default new Vuex.Store({
  modules,
  // plugins: [createPersistedState()],
  strict: process.env.NODE_ENV === 'development'
})