<template>
  <div class="home">

  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'Home',
});
</script>
<style>

</style>