import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "",
    component: () => import("../layouts/DashboardLayout.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/amendments',
        name: 'Amendments',
        component: () => import('../views/dashboard/Amendments.vue'),
        meta: { requiresAuth: true, requiresUserPermission:true  },
      },
      {
        path: '/exprired-id-cards',
        name: 'ExpiredIDCards',
        component: () => import('../views/dashboard/ExpiredIDCards.vue'),
        meta: { requiresAuth: true, requiresUserPermission:true  },
      },
      {
        path: '/unapproved-id-cards',
        name: 'UnapprovedIDCards',
        component: () => import('../views/dashboard/UnapprovedIDCards.vue'),
        meta: { requiresAuth: true, requiresUserPermission:true  },
      }, 
      {
        path: '/support',
        name: 'support',
        component: () => import('../views/dashboard/Support.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/law',
        name: 'law',
        component: () => import('../views/dashboard/Law.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/id-card',
        name: 'IdCard',
        component: () => import('../views/IdCard.vue'),
        meta: { requiresAuth: true, requiresUserPermission:true },
      },
      {
        path: '/organizations',
        name: 'Organizations',
        component: () => import('../views/Organizations.vue'),
        meta: { requiresAuth: true, requiresUserPermission:true },
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import('../views/Users.vue'),
        meta: { requiresAuth: true, requiresAdminPermission:true },
      },
      {
        path: '/evaluations',
        name: 'Evaluations',
        component: () => import('../views/Evaluations.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'Error403',
        name: 'Error403',
        component: () => import('../views/Error403.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'Error402',
        name: 'Error402',
        component: () => import('../views/Error402.vue'),
        meta: { requiresAuth: true },
      },
    ]
  },  
  {
    path: '/settings',
    name: "Settings",
    component: () => import('../layouts/SettingsLayout.vue'),
    children: [
      {
        path: 'customer-address',
        name: 'CustomerAddress',
        component: () => import('../views/settings/CustomerAddress.vue'),
        meta: { requiresAuth: true, requiresAdminPermission: true },
      },
      {
        path: 'billing-address',
        name: 'BillingAddress',
        component: () => import('../views/settings/BillingAddress.vue'),
        meta: { requiresAuth: true, requiresAdminPermission: true  },
      },
      {
        path: 'data-agreement',
        name: 'DataAgreement',
        component: () => import('../views/settings/DataAgreement.vue'),
        meta: { requiresAuth: true, requiresAdminPermission: true  },
      },
      {
        path: 'id-card-layout',
        name: 'IDCardLayout',
        component: () => import('../views/settings/IDCardLayout.vue'),
        meta: { requiresAuth: true, requiresAdminPermission: true  },
      },
      {
        path: 'additional-fields',
        name: 'IdAdditionalFields',
        component: () => import('../views/settings/IdAdditionalFields.vue'),
        meta: { requiresAuth: true, requiresAdminPermission: true  },
      },
      {
        path: 'miscellaneous',
        name: 'Miscellaneous',
        component: () => import('../views/settings/Miscellaneous.vue'),
        meta: { requiresAuth: true, requiresAdminPermission: true  },
      },



    ]
  },

  // PUBLIC ROUTES
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue') 
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue') 
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// CHECK AUTHENTICATION
import store from '@/store/index'

router.beforeEach(async (to, from, next) => {

  let requiresAuth = (to.matched.some(record => record.meta.requiresAuth))
  let requiresAdminPermission = (to.matched.some(record => record.meta.requiresAdminPermission))
  let requiresUserPermission = (to.matched.some(record => record.meta.requiresUserPermission))
  
  //  Check is Authenticated
  if (requiresAuth) {
    let isAuthenticated = await store.dispatch('auth/isAuthenticated')


    if (isAuthenticated && !requiresAdminPermission && !requiresUserPermission) {
      next()
      return
    } else if (isAuthenticated && requiresAdminPermission && !requiresUserPermission) {
      let hasAdminPermission = await store.dispatch('auth/hasAdminPermission') 

      if (hasAdminPermission) {
        next()
        return
      } else {
        // If not has Admin permission redirect to orgin page 
        // next({ name: from.name })
        next({ name: "Error403" })
        return
      }

    } else if (isAuthenticated && !requiresAdminPermission && requiresUserPermission) {
      //User Permission
      await store.dispatch("auth/refreshToken")
      let hasUserPermission = await store.dispatch('auth/hasUserPermission', to.name)
      if (hasUserPermission) {
        next()
        return
      } else {
        next({ name: "Error402" })
        return
      }
    }else if (from.name === "Login") {
      return
    } else {

      // TRY REFRESH TOKEN
      await store.dispatch("auth/refreshToken")
      //await store.dispatch("auth/refreshToken")

      isAuthenticated = await store.dispatch('auth/isAuthenticated')
      if (isAuthenticated) {
        // // console.log(error("IS NOW REAUTHENTICATED")
        next()
        return
      }

      next({ name: "Login" })
    }

  } else {
    next()
  }
})







export default router
